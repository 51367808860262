module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"edison-union","short_name":"starter","start_url":"/","background_color":"#FF6F84","theme_color":"#FF6F84","display":"standalone","icons":[],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-127969191-1","head":true,"cookieDomain":"edisonunion.co","anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    }]
